@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

body {
  opacity: 1 !important;
}

textarea:focus,
input:focus {
  outline: none;
}

button {
  border: none;
  background-color: inherit;
  color: inherit;
  font-family: inherit;
  text-align: inherit;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
